import { DEFAULT_TIMEZONE, DEFAULT_LOCALE } from 'shared/consts';

import { isToday } from 'utils/dateFilters';
import moment from 'moment-timezone';
import { formatDateOptions } from 'utils/formatDateOptions';

/**
 * Returns a MM/DD/YYYY formatted date string
 *
 * @param date user input string of the date string
 * @returns Formatted date string in MM/DD/YYYY format
 */
export const formatBirthdayDate = (date: string): string =>
  date
    .replace(/^(\d\d)(\d)$/g, '$1/$2')
    .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
    .replace(/[^\d/]/g, '');

/**
 * Returns a time formatted string based on timezone
 *
 * @param date date string
 * @returns Formatted time string based on timezone
 */
export const formatTime = (
  date: string,
  timeZone = DEFAULT_TIMEZONE,
  locale = DEFAULT_LOCALE,
  inLocalTimeLD = false,
): string => {
  // Remove once Helix removes Z from date-time strings https://ventureapp.atlassian.net/browse/SONIC-1501
  const localDate = inLocalTimeLD ? date.replace('Z', '') : date;
  return new Date(localDate).toLocaleTimeString(
    locale,
    formatDateOptions(timeZone, { timeStyle: 'short' }, inLocalTimeLD),
  );
};

/**
 * Returns a formatted date title based on locale and current date. e.g. Thursday, June 3, 2021 or Today, June 2, 2021
 * @returns Formatted date title based on locale and current date
 */
// eslint-disable-next-line default-param-last
export const formatDateTitle = (date: Date, todayString?: string, locale = DEFAULT_LOCALE, timeZone?: string) => {
  const dateIsToday = isToday(date, locale, timeZone);

  if (dateIsToday) {
    const dateString = date.toLocaleDateString(locale, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      timeZone,
    });

    return todayString ? `${todayString}, ${dateString}` : dateString;
  }
  return date.toLocaleDateString(locale, {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone,
  });
};

/**
 * formatEventDuration is currently only used in upcoming-bookings. The getBookings api return dates in UTC.
 */
export const formatEventDuration = (start_at: string, end_at: string, locale: string, timeZone: string) => {
  const startAtDate = new Date(start_at);
  const endAtDate = new Date(end_at);

  const formatEventStartTime = startAtDate.toLocaleString(
    locale,
    formatDateOptions(timeZone, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }),
  );
  const formatEventEndTime = endAtDate.toLocaleString(
    locale,
    formatDateOptions(timeZone, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }),
  );

  const eventTimeZone = moment(start_at).tz(timeZone).format('z');

  return {
    eventStartTime: formatEventStartTime,
    eventEndTime: formatEventEndTime,
    eventTimeZone,
  };
};
