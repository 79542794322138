import { ACTION_STATUSES, IMG_URL } from 'shared/consts';
import {
  DesktopButtonContainer,
  CheckoutWrapper,
  DesktopContentContainer,
  DesktopModalContainer,
  LeftPaneContainer,
  RightPaneContainer,
  StyledImage,
  DesktopTitleContainer,
} from './styles';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { TitleBlock } from '@hqo/react-components-library/dist/molecules/titleBlock';
import { TwoColumnContent } from '@hqo/react-components-library/dist/molecules/twoColumnContent';
import { CheckoutButton } from 'components/checkout-button';
import { Container } from 'hocs/shared-styles';
import { PaymentMethods } from 'components/payment-methods';
import React, { useMemo } from 'react';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { useIntl } from 'react-intl';
import { Cart } from 'components/cart';
import { CheckoutDesktopProps } from '../types';
import { useSelector } from 'react-redux';
import { selectSubmitCartStatus } from 'store/cart/selectors';

export const CheckoutDesktop = ({
  cart,
  onAddPaymentMethodClick,
  paymentMethods,
  currentPaymentMethodId,
  submittedPaymentMethodId,
  currentPaymentError,
  onRemovePaymentMethod,
  onPayClick,
  showAddCardForm,
  cartId,
  onCancelAddCardClick,
}: CheckoutDesktopProps) => {
  const intl = useIntl();
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const { total } = formatSummaryValues(cart?.total_summary);
  const buttonText = useMemo<string>(
    () =>
      cart?.total_summary?.total
        ? intl.formatMessage({ id: 'confirm_and_pay' })
        : intl.formatMessage({ id: 'confirm_and_book' }),
    [cart?.total_summary?.total],
  );
  const isDisabledButton = useMemo<boolean>(
    () => !currentPaymentMethodId || submitCartStatus === ACTION_STATUSES.PENDING || showAddCardForm,
    [currentPaymentMethodId, submitCartStatus, showAddCardForm],
  );

  return (
    <DesktopModalContainer>
      {!cart && !paymentMethods ? (
        <Container isLoading>
          <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
        </Container>
      ) : (
        <CheckoutWrapper>
          <TwoColumnContent
            isCheckoutDesktop
            isButtonVisible={false}
            LeftPaneContent={
              <LeftPaneContainer>
                <DesktopTitleContainer>
                  <TitleBlock title={intl.formatMessage({ id: 'checkout' })} />
                </DesktopTitleContainer>
                <DesktopContentContainer>
                  <PaymentMethods
                    enabledPaymentMethods={cart.ui_enablements}
                    paymentMethods={paymentMethods}
                    submittedPaymentMethodId={submittedPaymentMethodId}
                    currentPaymentError={currentPaymentError}
                    selectedPaymentMethodId={currentPaymentMethodId}
                    onRemovePaymentMethod={onRemovePaymentMethod}
                    onAddNewCard={onAddPaymentMethodClick}
                    cartId={cartId}
                    total={total}
                    showAddCardForm={showAddCardForm}
                    onCancelAddCardClick={onCancelAddCardClick}
                    cart={cart}
                    formTitle={intl.formatMessage({ id: 'payment' })}
                  />
                </DesktopContentContainer>
                <DesktopButtonContainer>
                  <CheckoutButton
                    text={buttonText}
                    isDisabled={isDisabledButton}
                    isLoading={submitCartStatus === ACTION_STATUSES.PENDING}
                    onClick={onPayClick}
                  />
                </DesktopButtonContainer>
              </LeftPaneContainer>
            }
            RightPaneContent={
              <RightPaneContainer>
                {
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  <StyledImage src={IMG_URL + cart.vendor?.logo_url} alt="service_logo" />
                }
                <Cart isCheckoutView cart={cart} />
              </RightPaneContainer>
            }
          />
        </CheckoutWrapper>
      )}
    </DesktopModalContainer>
  );
};
